<template>
<!--<header class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">-->
<!--  <a class="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#">FUCAI</a>-->
<!--  <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">-->
<!--    <span class="navbar-toggler-icon"></span>-->
<!--  </button>-->
<!--<header>-->
<!--  <div class="d-flex flex-column flex-md-row align-items-center pb-3 mb-4 border-bottom">-->
<!--    <a href="javascript:void(0)" class="d-flex align-items-center text-dark text-decoration-none">-->
<!--      <b-icon icon="award" class="me-3 text-danger" :width="40" :height="40"></b-icon>-->
<!--      <span class="fs-4">Fucai Console</span>-->
<!--    </a>-->
<!--    <Menu/>-->
<!--  </div>-->

<!--</header>-->

  <header class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
    <a class="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#">刮奖管理平台</a>
    <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <Menu/>
  </header>
</template>

<script>
import Menu from '@/components/Menu.vue'
export default {
  name: 'Header',
  components: {
    Menu
  }
}
</script>
