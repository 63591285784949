import Vue from 'vue'
import VueRouter from 'vue-router'
import Console from '../views/layout/Console'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: () => import('../views/Home'),
        meta: {

        }
    },
    // {
    //     path: '/',
    //     component: Console,
    //     meta: {
    //         title: '首页',
    //         isMenu: true
    //     },
    //     children: [
    //         {
    //             path:'/',
    //             name:'home.index',
    //             component: () => import('../views/Data.vue'),
    //             meta: {
    //                 title:'首页',
    //             }
    //         }
    //
    //     ]
    // },
    {
        path: '/data',
        component: Console,
        meta: {
            title: '数据中心',
            isMenu: true,
            requireAuth:true,
            permissions:[],
            roles:[1]
        },
        children: [
            {
                path: "/data",
                name: "data.center",
                component: () => import('../views/data/Index.vue'),
                meta: {
                    title: '数据中心',
                    isMenu: true,
                    requireAuth:true,
                    permissions:[],
                    roles:[1]
                }
            },
            {
                path: "/data/channel",
                name: "data.channel",
                component: () => import('../views/data/Channel.vue'),
                meta:{
                    title:'渠道数据',
                    isMenu:true
                }
            },

        ]
    },

    {
        path: '/channel',
        component: Console,
        meta: {
            title: '渠道管理',
            isMenu: true,
            requireAuth:true,
            permissions:[],
            roles:[1]
        },
        children: [
            {
              path: "/channel",
              name: 'channel.list',
              component: () => import('../views/channel/List'),
              meta: {
                title: '渠道管理',
                isMenu: true
              }
            },
            {
                path: '/channel/add',
                name: 'channel.add',
                component: () => import('../views/channel/Add.vue'),
                meta: {
                    title: '添加渠道',
                }
            },
            {
                path: '/channel/:id/view',
                name: 'channel.view',
                component: () => import('../views/channel/View.vue')
            },
            {
                path: '/channel/:id/edit',
                name: 'channel.edit',
                component: () => import('../views/channel/Edit.vue')
            },
            {
                path: '/channel/:id/data',
                name: 'channel.data',
                component: () => import('../views/channel/Data.vue'),
                meta: {
                    title: '渠道统计',
                    // isMenu: true
                }
            },
            {
                path: '/channel/recharge',
                name: 'channel.recharge',
                component: () => import('../views/channel/Recharge.vue'),
                meta: {
                    title: '渠道充值',
                    isMenu: true,
                    requireAuth:true,
                    permissions:[],
                    roles:[1]
                }
            },
            {
                path: '/channel/stock',
                name: 'channel.stock',
                component: () => import('../views/channel/Stock.vue'),
                meta: {
                    title: '渠道购票',
                    isMenu: true,
                    requireAuth:true,
                    permissions:[],
                    roles:[1]
                }
            },
        ]
    },
    {
        path: '/console/stat',
        component: Console,
        meta: {
            title: '数据统计',
            isMenu: true,
            requireAuth:true,
            permissions:[],
            roles:[2]
        },
        children: [
            // {
            //     path: "/console",
            //     name: 'console.index',
            //     component: () => import('../views/console/Index.vue'),
            //     meta: {
            //         title: '控制面板',
            //         isMenu: true
            //     },
            // },
            {
                path: "/console",
                name: 'console.index',
                component: () => import('../views/console/Index.vue'),
                meta: {
                    title: '控制面板',
                    // isMenu: true
                },
            },
            {
                path: "/console/stat",
                name: 'console.stat',
                component: () => import('../views/console/StatEl.vue'),
                meta: {
                    title: '数据统计',
                    isMenu: true
                },

            },
            {
                path: "/console/phone",
                name: "console.phone",
                component: () => import('../views/console/StatElPhone.vue'),
                meta:{
                    title:'兑奖信息',
                    isMenu:true
                }
            },
            {
                path: '/console/user/batch',
                name: 'console.user.batch',
                component: () => import('../views/console/user/Batch.vue'),
                meta: {
                    title: '导入记录',
                    // isMenu: true
                }
            },
            {
                path: '/console/support',
                name: 'console.support',
                component: () => import('../views/console/Support.vue'),
                meta: {
                    title: '客服设置',
                    // isMenu: true
                }
            },
            {
                path: '/console/config',
                name: 'console.config',
                component: () => import('../views/console/Config.vue'),
                meta: {
                    title: '设置',
                    // isMenu: true
                }
            },
            {
                path: '/console/client',
                name: 'console.client',
                component: () => import('../views/console/Client.vue'),
                meta: {
                    title: '客户管理',
                    // isMenu: true
                }
            }
            // {
            //     path: "/console/stock",
            //     name: "console.stock",
            //     component: () => import('../views/console/Stock.vue'),
            //     meta: {
            //         title: '库存查询',
            //         isMenu: true
            //     }
            // }
            // {
            //     path: '/console/search',
            //     name: 'console.search',
            //     component: () => import('../views/Search.vue'),
            //     meta: {
            //         title: '彩票查询',
            //         isMenu: true
            //     }
            // },
        ]
    },
    {
        path: '/search',
        component: Console,
        meta: {
            title: '彩票查询',
            // isMenu: true
        },
        children: [
            {
                path: '/search',
                name: 'search',
                component: () => import('../views/Search.vue'),
                meta: {
                    title: '彩票查询',
                    // isMenu: true
                }
            }
        ]

    },
    {
        path: '/admin',
        component: Console,
        meta: {
            title: '系统设置',
            isMenu: true,
            requireAuth:true,
            permissions:[],
            roles:[1]
        },
        children: [
            {
                path: "/admin",
                name: 'admin.index',
                component: () => import('../views/admin/Index.vue'),
                meta: {
                    title: '系统设置',
                    isMenu: true
                },
            },
            {
                path: "/admin/game",
                name: 'admin.game.index',
                component: () => import('../views/admin/game/Index.vue'),
                meta: {
                    title: '彩票批次',
                    isMenu: true
                },
            },
            {
                path: "/admin/game/add",
                name: 'admin.game.add',
                component: () => import('../views/admin/game/Add.vue'),
                meta: {
                    title: '增加批次',
                    // isMenu: true
                },
            },
            {
                path: '/admin/stock',
                name: 'admin.stock',
                component: () => import('../views/admin/Stock.vue'),
                meta: {
                    title: '库存管理',
                    isMenu: true,
                    requireAuth:true,
                    // permissions:['admin:stock','admin:*'],
                    roles:[1]
                }
            },
            // {
            //     path:'/admin/log',
            //     name: 'admin.log',
            //     component: () => import('../views/admin/log/Index.vue'),
            //     meta: {
            //         title: '日志查询',
            //         isMenu: true,
            //         requireAuth: true,
            //         // permissions: ['admin:log', 'admin:*'],
            //         roles: [1]
            //     }
            // },
            {
                path:'/admin/makeup',
                name: 'admin.makeup',
                component: () => import('../views/admin/makeup/Index.vue'),
                meta: {
                    title: '补票记录',
                    isMenu: true,
                    requireAuth: true,
                    // permissions: ['admin:makeup', 'admin:*'],
                    roles: [1]
                }
            },
            {
                path: '/admin/user',
                name: 'admin.user',
                component: () => import('../views/admin/user/Index.vue'),
                meta: {
                    title: '用户管理',
                    isMenu: true,
                    requireAuth: true,
                    // permissions: ['admin:user', 'admin:*'],
                    roles: [1]
                }
            }
        ]
    },
    {
        path: '/finance',
        component: Console,
        meta: {
            title: '财务',
            isMenu: true,
            requireAuth:true,
            permissions:[],
            roles:[3]
        },
        children: [
            {
                path: '/finance',
                name: 'finance.index',
                component: () => import('../views/finance/Index.vue'),
                meta: {
                    title: '订单查询',
                    isMenu: true,
                    requireAuth:true,
                    permissions:[],
                    roles:[3]
                }
            },

            {
                path: '/finance/data',
                name: 'finance.data',
                component: () => import('../views/finance/Data.vue'),
                meta: {
                    title: '库存管理',
                    isMenu: true,
                    requireAuth:true,
                    permissions:[],
                    roles:[3]
                }
            },
        ]
    },
    {
        path: '/message',
        component: Console,
        meta: {
            title: '消息中心',
            // isMenu: true
        },
        children: [
            {
                path: '/message',
                name: 'notification',
                component: () => import('../views/notification/Index.vue'),
                meta: {
                    title: '消息中心',
                    // isMenu: true
                }
            }
        ]
    },
    {
        path: '/user',
        component: Console,
        meta: {
            title: '用户'
        },
        children: [
            {
                path: '/user',
                name: 'user.profile',
                component: () => import('../views/user/Profile.vue'),
                meta: {
                    title: '账号中心',
                    isMenu: true
                }
            },
            {
                path: '/user/secret',
                name: 'user.secret',
                component: () => import('../views/user/Secret.vue'),
                meta: {
                    title: '安全设置',
                }
            },

            {
                path: '/user/recharge',
                name: 'user.recharge',
                component: () => import('../views/user/Recharge.vue'),
                meta: {
                    title: '充值记录',
                    isMenu: true
                }
            },
            {
                path: '/user/order',
                name: 'user.order',
                component: () => import('../views/user/Order.vue'),
                meta: {
                    title: '订单记录',
                    isMenu: true
                }
            }
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/user/Login.vue'),
        meta: {
            title: '登录'
        }
    },
]

const router = new VueRouter({
    linkExactActiveClass:'active',
    routes
});
export default router

router.beforeEach((to, from, next) => {
    let isLogin = localStorage.getItem("user");
    isLogin = Boolean(isLogin);

    if(to.path === "/login"){
        next();
    }else{
        if(isLogin) {
            // console.log(to)
            if(to.meta.requireAuth) {
                let user = JSON.parse(localStorage.getItem('user'))
                // console.log(user)
                let authed = false;

                if(to.meta.roles) {
                    for(let i in to.meta.roles) {
                        if(user.roles.indexOf(to.meta.roles[i]) >= 0) {
                            authed = true;
                        }
                    }
                }

                if(!authed) {
                    for(let i in to.meta.permissions) {
                        if(user.permissions.indexOf(to.meta.permissions[i]) >= 0) {
                            authed = true;
                            break;
                        }
                    }
                }

                if(authed) {
                    next();
                }else{
                    next("/403");
                }
            } else {
                next();
            }
        } else {
            next('/login')
        }
    }
})
