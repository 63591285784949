<template>

  <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light  collapse">
    <div class="position-sticky sidebar-sticky pt-3">
      <ul class="nav nav-pills flex-column  mt-3">
        <li class="nav-item" v-for="item in menus" v-bind:key="item.index">
          <router-link :to="{path:item.path}" class="nav-link">
            <b-icon icon="inboxes-fill"></b-icon>
            {{ item.meta.title }}
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>
<script>
  import User from "@/model/User";
export default {
  name: 'Sidebar',
  data(){
    return {
      menus: [],
      active: ''
    }
  },
  watch:{
    $route(){
      this.initMenus();
    }
  },
  created:function (){

    this.initMenus()
  },
  methods: {
    /**
     * 初始化左侧导航菜单
     * 导航菜单取自路由设置，由当前路由决定二级导航菜单
     */
    initMenus() {
      this.menus = [];
      let parent = '/' + this.$route.name.split('.')[0];
      for (let i in this.$router.options.routes) {
        let menu = this.$router.options.routes[i];
        let pathMenu='/'+menu.path.split("/")[1]
        if(parent === pathMenu) {
          for(let n in menu.children) {
            let child = menu.children[n];
            if(child.meta && child.meta.isMenu) {
              // 有权限要求的菜单需要检查当前登录用户的权限匹配
              if(child.meta.requireAuth) {
                if(User.access(child.meta)) {
                  this.menus.push(child);
                }
              } else {
                // 无权限要求直接推入菜单项
                this.menus.push(child);
              }
            }
          }
        }
      }
      console.log(this.$route.path)
      this.active = this.$route.path;
    }
  }
}
</script>
