import Vue from 'vue'
import store from './store'
import util from "@/util/util"
import Element from 'element-ui'


import 'element-ui/lib/theme-chalk/index.css';
import 'bootstrap-vue/node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/node_modules/bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.prototype.util = util;
Vue.config.productionTip = false;

if(process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development')
  // require('./mock/mock');
console.log(process.env.NODE_ENV)



import App from './App.vue'
import router from './router'

import {BVConfigPlugin, BootstrapVue, IconsPlugin, VBTooltipPlugin, ToastPlugin, SpinnerPlugin} from 'bootstrap-vue';
Vue.use(BVConfigPlugin, {
  BToast: { autoHideDelay: 5000,title:'系统通知',},
})
Vue.use(Element)

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VBTooltipPlugin);
Vue.use(ToastPlugin);
Vue.use(SpinnerPlugin);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
