export default{
    api: {
        'production':'https://cp.fc.iaplt.net/a',
    },
    user: {
        roles: {
            1: 'admin',
            2: 'channel',
            3: 'finance',
            4: 'sales'
        }
    }
}
