import axios from 'axios';
import store from '../store/index';
import Vue from 'vue';
import globalVariable from '@/config';
import router from "../router";

const baseUrl = globalVariable.api[process.env.NODE_ENV];

// form-data
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.transformRequest = [function (data) {
//     let ret = '';
//     for (let it in data) {
//         ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
//     }
//     return ret
// }];

/**
 * 提示函数
 * 禁止点击蒙层、显示一秒后关闭
 */
const toast = msg => {
    const vm = new Vue();
    vm.$bvToast.toast(msg);
};

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
    // this.router.path('/login')
    router.replace({
        path: '/login',
        // query: {
        //     redirect: router.currentRoute.fullPath
        // }
    });
};

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 * @param other
 */
const errorHandle = (status, other) => {
    console.log(status);
    // 状态码判断
    switch (status) {

        // 401: 未登录状态，跳转登录页
        case 401:
            toLogin();
            break;
        // 403 token过期
        // 清除token并跳转登录页
        case 403:
            toast('没有权限，请联系管理员');
            localStorage.removeItem('token');
            store.commit('loginSuccess', null);
            setTimeout(() => {
                toLogin();
            }, 1000);
            break;
        // 404请求不存在
        case 404:
            toast('请求的资源不存在');
            break;
        default:
            console.log(other);
    }};

// 创建axios实例
const instance = axios.create({
    baseURL: baseUrl,
    timeout: 5000
});
// 设置post请求头
// instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
    config => {
        // 登录流程控制中，根据本地是否存在token判断用户的登录情况
        // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
        // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
        // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。

        const token = localStorage.getItem('token');
        // token && (config.headers.Authorization = token);
        token && (config.headers.token = token);
        store.commit('showLoading');
        return config;
    },
    error => Promise.error(error)
);

// 响应拦截器
instance.interceptors.response.use(
    // 请求成功
    res => {
        store.commit('hideLoading');

        if (res.data.code === 1002) {
            toLogin();
        }

        if(res.status === 200 || res.status === '200') {
            return Promise.resolve(res);
        } else {
            Promise.reject(res);
        }
    },
    // 请求失败
    error => {
        store.commit('hideLoading');
        const { response } = error;
        if (response) {
            // 请求已发出，但是不在2xx的范围
            errorHandle(response.status, response.data.message);
            if (response.status!=401){
                toast('服务器繁忙，请稍后再试');
            }else{
                // toast('登录过期，请重新登录');
            }

            return Promise.reject(response);
        } else {
            // 处理断网的情况
            // eg:请求超时或断网时，更新state的network状态
            // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
            // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
            if (!window.navigator.onLine) {
                store.commit('changeNetwork', false);
            } else {
                if(error.request.readyState === 4 && error.request.status === 0) {
                    toast('服务器超时');
                    store.commit('hideLoading');
                } else {
                    toast('服务器繁忙，请稍后再试');
                }
                toast('服务器繁忙，请稍后再试');
                return Promise.reject(error);
            }
        }
    });

export default instance;
