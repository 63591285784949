import axios from "@/request/http";

const api = {
    get(path, params) {
        return axios.get(path, params);
    },

    getWithToken(path, params) {
        return axios.get(path, params,{
                headers:{
                'Authorization':localStorage.getItem("token")}
        });
    },

    post(path, params, config = {}) {
        return axios.post(path, params, config);
    },

    postJson(path, data) {
        let json = JSON.stringify(data);
        return axios.post(path, json, {
            transformRequest: [
                function(data) {
                    return data
                }
            ],
            headers:{'Content-Type':'application/json'}
        });
    },
    postJsonWithToken(path, data) {
        let json = JSON.stringify(data);
        return axios.post(path, json, {
            transformRequest: [
                function(data) {
                    return data
                }
            ],
            headers:{'Content-Type':'application/json',
                'Authorization':localStorage.getItem("token")}
        });
    },
}

export default api;
