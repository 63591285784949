import api from "@/request/api";

export default {
    /**
     * 获取当前登录用户信息
     * @param params
     * @returns {*}
     */
    find(params = {}) {
        return api.get('user/info', {params: params});
    },

    /**
     * 获取用户充值信息列表
     * @param params
     * @returns {*}
     */
    getRecharge(params = {}) {
        return api.get('user/recharge', {params: params});
    },

    /**
     * 获取订单
     * @param params
     * @returns {*}
     */
    getOrders(params = {}) {
        return api.get('user/order', {params: params});
    },

    /**
     * 登录
     * @param data
     * @returns {*}
     */
    login(data = {}) {
        return api.postJson('/user/login', data);
    },

    /**
     * 退出登录
     * @param data
     * @returns {*}
     */
    logout(data = {}) {
        return api.postJsonWithToken('/user/logout', data);
    },

    /**
     * 获取用户列表
     * @param params
     * @returns {*}
     */
    all(params) {
        return api.get('user/list', {params: params});
    },

    /**
     * 获取渠道用户列表
     * @param params
     * @returns {*}
     */
    findByChannel(params = {}) {
        return api.get('channel/user/list', {params: params});
    },

    /**
     * 保存用户信息
     * @param params
     * @returns {*}
     */
    save(params) {
        return api.post('user/save', {params: params});
    },

    /**
     * 根据角色id获取用户列表
     * @param rid integer
     * @returns {*}
     */
    getUsersByRole(rid) {
        return api.get('user/list', {rid:rid});
    },

    /**
     * 上传渠道用户信息
     * @param data
     * @returns {*}
     */
    upload(data) {

        return api.post('/user/batch/upload', data,{headers:{'Content-Type':'multipart/form-data'}})
    },

    isLogin() {
        let token = localStorage.getItem('token');
        return Boolean(token);
    },

    /**
     * 判断当前用户权限
     * @param meta
     * @returns {boolean}
     */
    access(meta) {
        let user = JSON.parse(localStorage.getItem('user'));
        if(!meta.requireAuth) {
            return true;
        } else {
            if(meta.roles && meta.roles.length > 0) {
                for(let i in meta.roles) {
                    if(user.roles && user.roles.indexOf(meta.roles[i]) >= 0) {
                        return true;
                    }
                }
            }
            for(let i in meta.permissions) {
                if(user.permission.indexOf(meta.permissions[i]) >= 0) {
                    return true;
                }
            }
        }

        return false;
    }
}
