<template>
  <div class="navbar-nav">
    <ul class="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
      <li v-for="menu in menus" v-bind:key="menu.index">
        <router-link :to="menu.path" class="nav-link px-2" active-class="active">
          {{ menu.meta.title}}
        </router-link>
      </li>
<!--      <li>-->
<!--        <router-link :to="{path:'/message'}" class="nav-link mr-4">-->
<!--          消息中心-->
<!--          <span class="position-absolute top-10 start-100 translate-middle badge rounded-pill bg-danger">-->
<!--          9-->
<!--          </span>-->
<!--        </router-link>-->
<!--      </li>-->
      <li v-if="user.username">
        <span class="nav-link px-2" >
          <b-icon icon="person"></b-icon>
          {{ user.username }}
        </span>
      </li>
      <li v-if="user.username">
        <a href="javascript:void(0);" class="nav-link px-2" @click="logout">退出</a>
      </li>
    </ul>
  </div>
</template>

<script>
import User from "@/model/User"
export default {
  name: 'Menu',
  data(){
    return {
      menus: [],
      user: {}
    }
  },
  mounted() {
    this.initMenus();
  },
  methods: {
    initMenus() {
      // 获取本地用户权限
      let u = localStorage.getItem('user');
      if(u) {
        this.user = JSON.parse(u);
      }
      let menus = [];
      // 菜单来自路由，标记isMenu为true的一级路由即显示在主菜单中
      for(let i in this.$router.options.routes) {
        let meta = this.$router.options.routes[i].meta;
        if(meta && meta.isMenu) {
          // 判断当前用户是否有此路由权限
          if(User.access(meta)){
            menus.push(this.$router.options.routes[i]);
          }
        }
      }
      this.menus = menus;
    },
    logout() {
      User.logout().then(response => {
        if(response.data.code === 0) {
          localStorage.removeItem('user');
          localStorage.removeItem('token');
          this.$router.replace({'path':'/login'})
        }
      });
    }
  }
}
</script>
